const MARGIN_FROM_NODE = 12;

export function getScrollParent(node: any): any {
	if (!node) {
		return null;
	}

	if (node.nodeType !== Node.TEXT_NODE && node.scrollWidth > node.clientWidth) {
		return node;
	} else {
		return getScrollParent(node.parentNode);
	}
}

export function getParentNode(node: any): HTMLElement | null {
	if (!node) {
		return null;
	}

	if (node.nodeType !== Node.TEXT_NODE) {
		return node;
	} else {
		return getParentNode(node.parentNode);
	}
}

export const getPosition = (range: Range) => {
	const contentRect = getParentNode(range.commonAncestorContainer)!.getBoundingClientRect();

	const portalContainer = document.querySelector(`#highlight-actions-portal-container`);

	const portalRect = portalContainer?.getBoundingClientRect() || {
		left: 0,
		top: 0,
	};

	const rect = range.getBoundingClientRect();

	const { left: leftRect, top, width } = rect;

	// Popup can't be rendered outside the body area
	let leftLimit = contentRect.left;
	let rightLimit = contentRect.right;

	// If the selected text has a parent that is scrollable for example like text inside table.
	// The popup is limited to be shown within the scrollable area.
	const firstParentThatCanScroll = getScrollParent(range.startContainer);
	if (firstParentThatCanScroll) {
		const scrollParentRect = firstParentThatCanScroll.getBoundingClientRect();
		leftLimit = Math.max(leftLimit, scrollParentRect.left);
		rightLimit = Math.min(rightLimit, scrollParentRect.right);
	}

	let x = width / 2 + leftRect;
	x = Math.max(leftLimit, Math.min(rightLimit, x));

	return {
		left: x - portalRect.left,
		top: top - portalRect.top + MARGIN_FROM_NODE,
	};
};
